<template>
  <div class="campaigns-table">
    <v-simple-table>
      <thead>
        <tr class="campaigns-table__header">
          <th>Name</th>
          <th>Emails</th>
          <th>Default</th>
          <th>Verified</th>
          <th />
        </tr>
      </thead>
      <tbody class="search-table__content">
        <template v-if="fromlines.length > 0">
          <tr v-for="(item, i) in fromlines" :key="i">
            <td class="py-1">
              <div class="campaigns-table__campaign-header mb-1">
                {{ item.fromName }}
              </div>
            </td>
            <td class="py-1">
              <div class="campaigns-table__campaign-subheader">
                <div class="pt-1">
                  <span class="font-weight-bold black--text from-to-text">From Email: </span>
                  {{ item.fromEmail }}
                </div>
                <div class="pt-1">
                  <span class="font-weight-bold black--text from-to-text">Reply Email: </span>
                  {{ item.replyEmail }}
                </div>
              </div>
            </td>

            <td class="py-1">
              <v-checkbox v-model="item.isDefault" disabled />
            </td>

            <td class="py-1">
              <v-checkbox v-model="item.isVerified" disabled />
            </td>

            <td class="v-menu-attach-parent">
              <v-menu
                v-if="getActions(item).length"
                nudge-top="-5px"
                nudge-left="15px"
                :offset-y="true"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="elevation-0 outlined-btn  px-12 "
                    height="34px"
                    plain
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('click')"
                  >
                    Actions
                  </v-btn>
                </template>
                <OuterList
                  title="Actions"
                  :items="getActions(item)"
                  @click="$emit('action', { event: $event, item })"
                />
              </v-menu>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td colspan="5">
              <div>Currently, you do not have a From Line set up and verified. Please add one in order to setup an email campaign.</div>
            </td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import OuterList from "@/sharedComponents/OuterList";

export default {
  name: "CreativesTable",
  components: { OuterList },
  props: {
    fromlines: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      menuOpened: false,
      outerListItems: [
        {
          label: "Edit",
          icon: "mdi-pencil",
          value: "edit",
        },
        {
          label: "Verify Fromline",
          icon: "mdi-email",
          value: "verify",
        },
        {
          label: "Check DNS Records",
          icon: "mdi-magnify",
          value: "dns",
        },
        {
          label: "Delete",
          icon: "mdi-delete-outline",
          value: "delete",
        },
      ],
    };
  },
  methods: {
    getActions(item) {
      if (!this.$store.getters["user/hasWritePermission"]) {
        return [];
      }

      const actions = item.isDefault ? this.outerListItems.slice(0,3) : this.outerListItems;

      return item.isVerified ? [actions[0], actions[actions.length-1]] : actions
    }
  },
};
</script>

<style lang="scss" scoped>
.panel-button {
  font-family: "Open Sans", sans-serif;
  font-size: 13px !important;
  color: #66788e;
  text-transform: none;
  background: #ecf1f5 !important;

  ::v-deep span.v-btn__content {
    opacity: 1 !important;
  }
}
.panel-button[aria-expanded=true] {
  color: white;
  background: #2b84eb !important;
  opacity: 1 !important;
}

.from-to-text {
  font-family: "Open Sans", sans-serif;
  font-size: 13px !important;
}
</style>
