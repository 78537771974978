<template>
  <v-card class="popup-card">
    <v-card-title class="px-6 pt-4 pb-4">
      <div v-if="id !== undefined && id !== null">
        Edit From Line
      </div>
      <div v-else>
        Create From Line
      </div>
    </v-card-title>
    <v-divider v-if="$vuetify.breakpoint.xsOnly" />
    <v-card-text class="px-6 pt-5">
      <validation-observer ref="textInput">
        <CustomTextInput
          v-model="fromEmail"
          header="From Email"
          class="flex-grow-1"
          placeholder="info@example.com"
          percentage="7.5%"
          :maxlength="150"
          :required="true"
          email
        />
        <CustomTextInput
          v-model="fromName"
          class="flex-grow-1"
          header="From Name"
          placeholder="Example Inc."
          percentage="7.5%"
          :maxlength="150"
          :required="true"
        />
        <CustomTextInput
          v-model="replyEmail"
          class="flex-grow-1"
          header="Reply to Email"
          placeholder="noreply@example.com"
          percentage="7.5%"
          :maxlength="150"
          :required="true"
          email
        />
        <v-checkbox v-model="isDefault" label="Is Default" class="mt-4" />
      </validation-observer>
      <v-card-actions class="d-flex justify-end align-center pa-0 mt-5">
        <div
          class="simple-text-button button--gray px-9"
          @click="$emit('dismiss')"
        >
          Cancel
        </div>

        <v-btn
          class="custom-button custom-button--blue px-15"
          height="34px"
          depressed
          @click="on_save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import CustomTextInput from "@/sharedComponents/CustomTextInput";
export default {
  name: "FromLineEdit",
  components: { CustomTextInput },
  props:{
    fromLine:{
      default: () => ({})
    },
  },
  data(){
    return {
      fromEmail: "",
      fromName: "",
      id: null,
      isDefault: false,
      isVerified: false,
      replyEmail: ""
    }
  },
  created(){
    this.fromEmail = this.fromLine.fromEmail
    this.fromName = this.fromLine.fromName
    this.id = this.fromLine.id
    this.replyEmail = this.fromLine.replyEmail
    this.isDefault = this.fromLine.isDefault
  },
  methods: {
    async on_save(){
      if (!await this.$refs.textInput.validate()) {
        return;
      }

      this.$emit('save', {
        fromEmail: this.fromEmail,
        fromName: this.fromName,
        id: this.id || false,
        isDefault: this.isDefault,
        replyEmail: this.replyEmail
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.popup-card {
  .v-input--checkbox {
    margin-top: 4px;

    .v-label {
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      font-weight: 600;
      color: #241c15;
    }
    .v-icon {
      font-size: 18px;
    }
    .v-messages {
      display: none;
    }
  }
}
</style>

