<template>
  <v-container class="mt-3 px-sm-10 px-3 segments-accordion elevation-0" fluid>
    <PageHeader
      header-text="From Lines"
      regular-button-text="New From Line"
      :regular-button-visible="$store.getters['user/hasWritePermission']"
      @regular-button-click="dialog = true"
    >
      <template #subheader>
        Manage From Lines for your email campaigns
      </template>
    </PageHeader>

    <SearchFieldRowWithPagination
      search-field-placeholder="Search for fromlines"
      :current-page-number="fromlines_page_number"
      :current-page-size="fromlines_page_size"
      :count-total="fromlines_total"
      :page-sizes="[25, 50, 100]"
      @next-page="get_fromlines_next_page"
      @prev-page="get_fromlines_prev_page"
      @change-page-size="fromlines_change_page_size"
      @search="(search_string) => on_search(search_string)"
    />

    <FromLinesTable
      class="from-line-table"
      :fromlines="fromlines"
      @action="handleAction($event)"
    />

    <v-dialog v-model="dialog" scrollable max-width="560px" @click:outside="edited_fromline = {}">
      <FromLineEdit
        v-if="dialog"
        :from-line="edited_fromline"
        @save="save_fromline"
        @dismiss="dialog = false; edited_fromline = {}"
      />
    </v-dialog>

    <v-dialog v-model="dnsDialog" scrollable max-width="560px" @click:outside="edited_fromline = {}">
      <FromLineDns
        v-if="dnsDialog"
        :from-line="edited_fromline"
        @dismiss="dnsDialog = false; edited_fromline = {}"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import FromLinesTable from "@/views/Campaigns/components/FromLines/FromLinesTable";
import FromLineEdit from "@/views/Campaigns/components/FromLineEdit";
import FromLineDns from "@/views/Campaigns/components/FromLineDns";
import SearchFieldRowWithPagination from "@/sharedComponents/SearchFieldRowWithPagination";

export default {
  name: "FromLinesView",
  metaInfo: {
    title: 'From Lines'
  },
  components: {
    FromLineEdit,
    FromLineDns,
    PageHeader,
    FromLinesTable,
    SearchFieldRowWithPagination
  },
  data() {
    return {
      edited_fromline: {},
      dialog: false,
      dnsDialog:false,
      fromlines: [],
      fromlines_total: 0,
      fromlines_page_number: 1,
      fromlines_page_size: 25,
      search_string: null,
    };
  },
  created() {
    this.get_available_fromlines();
  },
  methods: {
    async handleAction(action) {
      if (action.event.value === "delete") {
        await this.$rest.fromlines.delete_resource(action.item.id);
        await this.get_available_fromlines();
      } else if(action.event.value === "edit") {
        this.edited_fromline = {...action.item}
        this.dialog = true;
      } else if(action.event.value === "dns") {
        this.edited_fromline = {...action.item}
        this.dnsDialog = true;
      } else if(action.event.value === "verify") {
        await this.$rest.fromlines.verify_fromline(action.item.id);
        this.$notifier.info(`Please check the "From Email" inbox and follow the instructions within the message to complete the verification.`)
      }
    },
    async on_search(search_string) {
      this.search_string = search_string;
      await this.get_available_fromlines();
    },
    async save_fromline(fromline){
      this.dialog = false;
      if (fromline.id){
        await this.$rest.fromlines.put_resource(fromline.id, fromline);
      } else {
        await this.$rest.fromlines.post_resource(fromline);
        this.$store.commit('snackbar/showMessage', {
          color: "secondary",
          content: "From Line successfully added. A verification has been sent to the \"From Email\", with further instructions."
        })
      }
      this.edited_fromline = {};
      await this.get_available_fromlines()
    },

    async fromlines_change_page_size(size) {
      this.fromlines_page_size = size;
      this.fromlines_page_number = 1;
      await this.get_available_fromlines();
    },

    async get_fromlines_next_page() {
      this.fromlines_page_number += 1;
      await this.get_available_fromlines();
    },
    async get_fromlines_prev_page() {
      this.fromlines_page_number -= 1;
      await this.get_available_fromlines();
    },

    async get_available_fromlines() {
      const resp = await this.$rest.fromlines.get_collection({
        limit: this.fromlines_page_size,
        page: this.fromlines_page_number,
        fromName: this.search_string || null,
      });
      this.fromlines = resp.data.items;
      this.fromlines_total = resp.data.totalCount;
    },
  },
};
</script>

<style scoped>
.from-line-table {
  margin-top: 40px;
}
</style>
