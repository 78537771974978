<template>
  <v-card class="popup-card">
    <v-card-title class="px-6 pt-4 pb-4">
      <div>
        From Line DNS Checker
      </div>
    </v-card-title>
    <v-divider v-if="$vuetify.breakpoint.xsOnly" />
    <v-card-text class="px-6 pt-5">
      <validation-observer ref="textInput">
        <div class="input-label mb-0">
          MX Records
        </div>
        <v-col
          class="dns-entry"
          cols="12"
          :class="{ 'no-records-found': mxRecords.length === 0}"
        >
          <template
            v-if="mxRecords.length > 0"
          >
            <div
              v-for="(mxRecord, index) in mxRecords"
              :key="'mx-' + index"
            >
              {{ mxRecord }}
            </div>
          </template>
          <template v-else>
            <div>No MX records found.</div>
          </template>
        </v-col>

        <div class="input-label mb-0 mt-5">
          SPF Records (include:pulsehealth.tech)
        </div>
        <v-col
          class="dns-entry"
          cols="12"
          :class="{
            'no-records-found': spfRecords.length === 0,
            'warning-record': warningSpf
          }"
        >
          <template
            v-if="spfRecords.length > 0"
          >
            <div
              v-for="(spf, index) in spfRecords"
              :key="'spf-' + index"
            >
              {{ spf }}
            </div>
          </template>
          <template v-else>
            <div>No SPF record Found</div>
          </template>
        </v-col>

        <div class="input-label mb-0 mt-5">
          DKIM #1 ({{ dkim1Search }})
        </div>
        <v-col
          class="dns-entry"
          cols="12"
          :class="{ 'no-records-found': dkim1Records.length === 0}"
        >
          <template
            v-if="dkim1Records.length > 0"
          >
            <div
              v-for="(dkim1, index) in dkim1Records"
              :key="'dkim1-' + index"
            >
              {{ dkim1 }}
            </div>
          </template>
          <template v-else>
            <div>No CNAME records found for this DKIM.</div>
          </template>
        </v-col>

        <div class="input-label mb-0 mt-5">
          DKIM #2 ({{ dkim2Search }})
        </div>
        <v-col
          class="dns-entry"
          cols="12"
          :class="{ 'no-records-found': dkim2Records.length === 0}"
        >
          <template
            v-if="dkim2Records.length > 0"
          >
            <div
              v-for="(dkim2, index) in dkim2Records"
              :key="'dkim2-' + index"
            >
              {{ dkim2 }}
            </div>
          </template>
          <template v-else>
            <div>No DKIM records found.</div>
          </template>
        </v-col>

        <div class="input-label mb-0 mt-5">
          DKIM #2 Alt ({{ dkim2AltSearch }})
        </div>
        <v-col
          class="dns-entry"
          cols="12"
          :class="{ 'no-records-found': dkim2AltRecords.length === 0}"
        >
          <template
            v-if="dkim2AltRecords.length > 0"
          >
            <div
              v-for="(dkim2, index) in dkim2AltRecords"
              :key="'dkim2-alt-' + index"
            >
              {{ dkim2 }}
            </div>
          </template>
          <template v-else>
            <div>No CNAME records found for this DKIM. .</div>
          </template>
        </v-col>

        <div class="input-label mb-0 mt-5">
          DMARC Record
        </div>
        <v-col
          class="dns-entry"
          cols="12"
          :class="{ 'no-records-found': dmarcRecords.length === 0}"
        >
          <template
            v-if="dmarcRecords.length > 0"
          >
            <div
              v-for="(dmarcRecord, index) in dmarcRecords"
              :key="'dmarcRecord-' + index"
            >
              {{ dmarcRecord }}
            </div>
          </template>
          <template v-else>
            <div>No DMARC record found.</div>
          </template>
        </v-col>

        <div class="input-label mb-0 mt-5">
          Google Postmaster
        </div>
        <v-col
          class="dns-entry"
          cols="12"
          :class="{ 'no-records-found': googlePostmasterRecords.length === 0}"
        >
          <template
            v-if="googlePostmasterRecords.length > 0"
          >
            <div
              v-for="(pmRecord, index) in googlePostmasterRecords"
              :key="'pmRecord-' + index"
            >
              {{ pmRecord }}
            </div>
          </template>
          <template v-else>
            <div>No Google Postmaster records found.</div>
          </template>
        </v-col>

        <div class="input-label mb-0 mt-5">
          Bounce Subdomain
        </div>
        <CustomTextInput
          v-model="bounceDomain"
          class="flex-grow-1 mt-3"
          placeholder="ex: 'mailing' not the FQDN"
          :maxlength="30"
        />
      </validation-observer>
      <div v-if="searchOccurred" class="input-label mb-0 mt-5">
        Subdomain (CNAME mailing.pulsehealth.tech)
      </div>
      <v-col
        v-if="searchOccurred"
        class="dns-entry"
        cols="12"
        :class="{
          'no-records-found': bounceDomainRecords.length === 0,
          'warning-record': warningBounceDomain
        }"
      >
        <template
          v-if="bounceDomainRecords.length > 0"
        >
          <div
            v-for="(domain, index) in bounceDomainRecords"
            :key="'domain-' + index"
          >
            {{ domain }}
          </div>
        </template>
        <template v-else>
          <div>No bounce/subdomain record found.</div>
        </template>
      </v-col>
      <v-card-actions class="d-flex justify-end align-center pa-0 mt-5">
        <div
          class="simple-text-button button--gray px-9"
          @click="$emit('dismiss')"
        >
          Close
        </div>

        <v-btn
          class="custom-button custom-button--blue px-15"
          height="34px"
          depressed
          @click="reCheck"
        >
          Re-Check
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>

import CustomTextInput from "@/sharedComponents/CustomTextInput";

export default {
  name: "FromLineDns",
  components: {
    CustomTextInput
  },
  props:{
    fromLine:{
      default: () => ({})
    },
  },
  data(){
    return {
      mxRecords: [],
      dmarcRecords: [],
      spfRecords: [],
      dkim1Records:[],
      dkim2Records:[],
      dkim2AltRecords:[],
      dkim1Search: "",
      dkim2Search: "",
      dkim2AltSearch: "",
      googlePostmasterRecords:[],
      warningSpf: false,
      bounceDomain: "",
      searchOccurred:false,
      bounceDomainRecords: [],
      warningBounceDomain: false,
    }
  },
  created(){
    this.getDns(this.fromLine.id);

  },
  methods: {
    async reCheck() {
      if (!await this.$refs.textInput.validate()) {
        return;
      }
      this.searchOccurred = true;
      await this.getDns(this.fromLine.id);

    },

    async getDns(id) {

      let resp = [];

      if (this.bounceDomain){
        resp = await this.$rest.fromlines.get_fromline_dns(id, { subdomain:this.bounceDomain });
      }else{
        resp = await this.$rest.fromlines.get_fromline_dns(id);

      }
      const data = resp.data;

      //reset manual entries
      this.bounceDomainRecords = [];


      // Load MX records
      if (data["mx"] && data["mx"]["records"]) {
        this.mxRecords = data["mx"]["mxRecords"];
      }

      // Load SPF records
      if (data["spf"] && data["spf"]["records"]) {
        this.spfRecords =  data["spf"]["spfRecords"];

        //combine records to perform search (should only be one record being return)
        let searchString = "";
        this.spfRecords.forEach(value => {
          searchString += value + "\n";
        });
        if (!searchString.includes("pulsehealth.tech")) {
          this.warningSpf=true;
        }
      }

      // Load DKIM1 records
      this.dkim1Search = data["dkim-1"]["recordType"] + " " + data["dkim-1"]["domain"];
      if (data["dkim-1"] && data["dkim-1"]["records"]) {
        this.dkim1Records = data["dkim-1"]["dkimRecords"];
      }

      // Load DKIM2 records
      this.dkim2Search = data["dkim-2"]["recordType"] + " " + data["dkim-2"]["domain"];
      if (data["dkim-2"] && data["dkim-2"]["records"]) {
        this.dkim2Records = data["dkim-2"]["dkimRecords"];
      }

      // Load DKIM2 records
      this.dkim2AltSearch = data["dkim-2-alt"]["recordType"] + " " + data["dkim-2-alt"]["domain"];
      if (data["dkim-2-alt"] && data["dkim-2-alt"]["records"]) {
        this.dkim2AltRecords = data["dkim-2-alt"]["dkimRecords"];
      }

      // Load DMARC record
      if (data["dmarc"] && data["dmarc"]["records"]) {
        this.dmarcRecords = data["dmarc"]["dmarcRecord"];
      }

      // Load postmaster records
      if (data["google-postmaster"] && data["google-postmaster"]["records"]) {
        this.googlePostmasterRecords = data["google-postmaster"]["googlePostMasterRecords"];
      }

      // Load bounce domain records
      if (data["bounce-domain"] && data["bounce-domain"]["records"]) {
        this.bounceDomainRecords = data["bounce-domain"]["subdomain"];
        let searchDomains = "";
        this.bounceDomainRecords.forEach(value => {
          searchDomains += value;
        });
        if (searchDomains != "mailing.pulsehealth.tech") {
          this.warningBounceDomain=true;
        }

      }

    }

  },
}
</script>

<style lang="scss" scoped>
.popup-card {
  .v-input--checkbox {
    margin-top: 4px;

    .v-label {
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      font-weight: 600;
      color: #241c15;
    }
    .v-icon {
      font-size: 18px;
    }
    .v-messages {
      display: none;
    }
  }
}

.dns-entry {
  border: 1px solid #bbbbbb;
  border-radius: 4px;
  background-color: #96e874;
}
.no-records-found {
  background-color: #d9705d;
}
.warning-record {
  background-color: #e8ae6a !important;
}

</style>

